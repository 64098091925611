import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import dayjs from "dayjs";
import PropTypes from "prop-types";

const HeaderComponent = ({ data }) => {
  const { title, owner, createdAt } = data ?? {};
  const { firstName, lastName } = owner ?? {};

  return (
    <>
      <Typography
        paragraph
        sx={{
          fontSize: { lg: "2.5rem", md: "2.25rem", sm: "2rem", xs: "1.75rem" }
        }}
        style={{ fontWeight: 700, lineHeight: 1.25 }}
      >
        {title}
      </Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar
          sx={{ marginRight: 2, width: 40, height: 40 }}
          src={owner?.images?.image150}
        />

        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            {firstName && firstName + " " + lastName}
          </Typography>
          <Typography variant="caption">
            {dayjs(createdAt).format("dddd, DD MMM YYYY")}
          </Typography>
        </div>
      </div>
    </>
  );
};

HeaderComponent.propTypes = {
  data: PropTypes.object
};

export default HeaderComponent;
