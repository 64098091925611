import { useTheme } from "@mui/material";
import AsyncCreatableSelect from "react-select/async-creatable";
import { array, func, object, string } from "prop-types";
import { searchTagsByName } from "../../../api/tagApi";
import useTranslation from "../../../hooks/useTranslation";

const SelectTag = ({
  onChange,
  tagsSelected,
  inputId,
  customTheme,
  customStyles,
  // eslint-disable-next-line react/prop-types
  token,
  defaultTags
}) => {
  const theme = useTheme();
  const language = "en";
  const { t } = useTranslation(language);
  const styles = {
    menu: provided => ({
      ...provided,
      color: theme.palette.sidebar
        ? theme.palette.sidebar.contrast
        : "defaultColor"
    })
  };

  const selectTheme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#f5f4f1",
      primary: "blue",
      neutral80: "black",
      neutral0: "#f5f4f1"
    }
  });
  const getSearchTag = async inputValue => {
    if (!inputValue || inputValue.length < 3) {
      return [];
    }

    try {
      const response = await searchTagsByName(token, inputValue);
      let tags = response.map(tag => ({
        id: tag.id,
        value: tag.id,
        label: tag.t.name
      }));
      return tags;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  return (
    <div>
      <AsyncCreatableSelect
        placeholder={t("select_tag_placeholder")}
        cacheOptions={false}
        defaultOptions
        loadOptions={getSearchTag}
        noOptionsMessage={() => <span>{t("option_message_tag")}</span>}
        onChange={onChange}
        isValidNewOption={inputValue => inputValue.length >= 3}
        value={tagsSelected}
        defaultValue={defaultTags}
        inputId={inputId || "select-tags"}
        isMulti
        theme={selectTheme || customTheme}
        styles={styles || customStyles}
      />
    </div>
  );
};

SelectTag.propTypes = {
  customTheme: func,
  onChange: func.isRequired,
  tagsSelected: array.isRequired,
  inputId: string,
  customStyles: object,
  defaultTags: object
};

export default SelectTag;
