import React from "react";
import CreateArticleForm from "../../components/CreateArticleForm";

import { useParams } from "react-router-dom";

const Editor = () => {
  const { id } = useParams();
  return <CreateArticleForm articleId={id} />;
};
export default Editor;
    