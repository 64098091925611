import { getApi } from "./Api";

export const getSelf = token => {
  try {
    return getApi(token).get("accounts/me");
  } catch (e) {
    console.error(e);
  }
};

export default getSelf;
