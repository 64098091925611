import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const LeftRightFlex = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
        display: "block"
    },
    "& .left": {
        height: 240,
        width: 240,
        marginRight: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            height: "100%",
            width: "100%"
        }
    },
    "& .right": {
        flex: 1
    }
}));
