import { AES } from "crypto-js";
import Utf8 from "crypto-js/enc-utf8";
import configs from "./configs";
const { secretKey } = configs;

export const encrypt = str => {
  return AES.encrypt(str, secretKey).toString();
};

export const decrypt = str => {
  return AES.decrypt(str, secretKey).toString(Utf8);
};
