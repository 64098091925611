import { Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { func, number, object } from "prop-types";

const useStyles = makeStyles({
  categoryBox: {
    margin: 4,
    flexShrink: 1,
    width: "fit-content"
  }
});

const CategoryTag = ({ categories, onDelete, index }) => {
  const classes = useStyles();
  const categoryName = categories.label || categories?.value;

  const handleDelete = () => onDelete(index);
  return (
    <div className={classes.categoryBox}>
      <Chip
        size="small"
        label={categoryName}
        onDelete={handleDelete}
        color="primary"
      />
    </div>
  );
};

CategoryTag.propTypes = {
  categories: object.isRequired,
  onDelete: func.isRequired,
  index: number.isRequired
};

export default CategoryTag;
