import { getApi } from "./Api";

export const getGeneralCategories = async token => {
  try {
    const response = await getApi(token).get("/ttx/tree/general-category");
    return response.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat mengambil kategori", error);
    throw error; // Melemparkan kembali error untuk menyampaikannya ke pemanggil
  }
};
