import React, { useState, useEffect } from "react";
import { Fade, Modal, Backdrop, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getGeneralCategories } from "../../../api/categoryApi";
import { bool, func } from "prop-types";
import useTranslation from "../../../hooks/useTranslation";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    zIndex: theme.zIndex.modal + 1
  },
  root: {
    maxHeight: 460,
    width: "100vw",
    height: "100vh",
    maxWidth: 400,
    overflow: "hidden scroll"
  }
}));

const SelectCategoryModal = props => {
  const {
    onCategoryClicked = () => {},
    onClose = () => {},
    open = false
  } = props;

  const language = "en";
  const { t } = useTranslation(language);

  const classes = useStyles();
  const [generalCategory, setGeneralCategory] = useState([]);

  const getGeneralCategory = async () => {
    try {
      const generalCategories = await getGeneralCategories();
      const generalCategoriesData = generalCategories || [];
      setGeneralCategory(generalCategoriesData);
    } catch (error) {
      console.error("Error fetching general categories", error);
    }
  };

  const getActionOnClick = (hasChildren, data) => {
    hasChildren
      ? setGeneralCategory(data.children)
      : (onCategoryClicked(data), onClose());
  };

  const handleClose = async () => {
    await onClose();
    setGeneralCategory(generalCategory);
  };

  useEffect(() => {
    getGeneralCategory();
  }, []);

  const categoryList = () => {
    const [displayedCategories, setDisplayedCategories] = useState([]);
    const [addCategory, setAddCategory] = useState([]);

    const handleCategoryClick = (hasChildren, categories) => {
      if (addCategory.includes(categories.id)) {
        setAddCategory(prevCategories =>
          prevCategories.filter(categoryId => categoryId !== categories.id)
        );
        setDisplayedCategories(prevCategories =>
          prevCategories.filter(categoryId => categoryId !== categories.id)
        );
      } else {
        setAddCategory(prevCategories => [...prevCategories, categories.id]);
        if (!displayedCategories.includes(categories.id)) {
          setDisplayedCategories(prevCategories => [
            ...prevCategories,
            categories.id
          ]);
        }
      }
      if (!addCategory.includes(categories.id)) {
        setDisplayedCategories(prevCategories =>
          prevCategories.filter(categoryId => categoryId !== categories.id)
        );
      }
      getActionOnClick(hasChildren, categories);
    };

    const renderCategory = (categories, parentBold = false) => {
      const hasChildren = categories.children && categories.children.length > 0;
      const categoryId = categories.id;
      const isAddedCategory = addCategory.includes(categoryId);
      const isDisplayedCategory = displayedCategories.includes(categoryId);
      const isBold = props.isCategoryBold(categoryId);

      return (
        <ListItem
          key={categoryId}
          button
          onClick={() => handleCategoryClick(hasChildren, categories)}
          style={{
            padding: 16,
            fontWeight: isBold ? "bold" : "normal"
          }}
        >
          <ListItemText
            primary={
              <div>
                {isBold ? (
                  <strong>{`${categories.t.name}`}</strong>
                ) : (
                  `${categories.t.name}`
                )}
              </div>
            }
          />

          {hasChildren && (
            <ListItemSecondaryAction>
              <ArrowForwardIosIcon
                style={{ opacity: 0.6, height: 16, width: 16 }}
              />
            </ListItemSecondaryAction>
          )}

          {hasChildren && isDisplayedCategory && !isAddedCategory && (
            <List style={{ paddingLeft: 16 }}>
              {categories.children.map(child =>
                renderCategory(child, isAddedCategory || parentBold)
              )}
            </List>
          )}
        </ListItem>
      );
    };

    return (
      <List>
        {generalCategory.map(categories => renderCategory(categories))}
      </List>
    );
  };

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Box className={classes.paper}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: -16,
              padding: 16,
              marginBottom: 0,
              boxShadow:
                "0px 38px 80px rgba(0, 0, 0, 0.07), 0px 8.4878px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.52704px 5.32008px rgba(0, 0, 0, 0.0282725)"
            }}
          >
            <ArrowBackIosIcon
              onClick={() =>
                generalCategory === generalCategory
                  ? handleClose()
                  : setGeneralCategory(generalCategory)
              }
              style={{ cursor: "pointer", height: 16, width: 16 }}
            />
            <Typography style={{ fontWeight: "bold" }}>
              {t("category_list")}
            </Typography>
          </div>
          <List dense className={classes.root}>
            {categoryList()}
          </List>
        </Box>
      </Fade>
    </Modal>
  );
};

SelectCategoryModal.propTypes = {
  onCategoryClicked: func.isRequired,
  onClose: func.isRequired,
  open: bool.isRequired,
  isCategoryBold: func.isRequired
};

export default SelectCategoryModal;
