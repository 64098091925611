import language from "./../constant/language";
import { useQuery } from "./useQuery";

const useTranslation = () => {
  const query = useQuery();

  const choosedLang = language[query.get("lang") || "en"];

  const t = text => {
    return choosedLang[text];
  };
  return {
    t
  };
};

export default useTranslation;
