import { slugify } from "../utils/utils";
import { getApi } from "./Api";

export const createNewTagByName = async (token, name) => {
  try {
    // TODO: penyesuaian endpoint API
    const response = await getApi(token).post("tx", {
      name,
      slug: slugify(name),
    });
    return response.data;
  } catch (e) {
    console.error("An error occurred when creating tag", e);
  }
};

export const searchTagsByName = async (token, keyword) => {
  try {
    const response = await getApi(token).get(`ttx/tag/search/${keyword}`);
    return response.data;
  } catch (e) {
    console.error("An error occurred when searching tag", e);
    throw e;
  }
};
