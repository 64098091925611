import SelectCategoryModal from "./SelectCategoryModal";
import { Box, Typography, ButtonBase, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { array, func } from "prop-types";
import CategoryTag from "./CategoryTag";
import clsx from "clsx";
import useTranslation from "../../../hooks/useTranslation";

const useStyles = makeStyles(({ palette }) => ({
  radioGroup: {
    display: "flex",
    justifyContent: "center"
  },
  buttonAddCategory: {
    padding: "4px 12px",
    borderRadius: 20,
    background: "rgba(0,0,0,0.05)",
    transition: "all .2s ease-in",
    "&:hover": {
      background: "rgba(0,0,0,0.1)"
    }
  },
  background: {
    padding: 8,
    marginTop: 16,
    border: `1px solid ${palette.text.primary}`,
    borderRadius: 4
  }
}));
const CreateCategory = ({
  categories,
  onCategoryClicked,
  onDeleteTag,
  className
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const isCategoryBold = categoryId => {
    const selectedCategories =
      categories?.map(category => category.value && category.id) || [];
    return selectedCategories.includes(categoryId);
  };

  return (
    <div className={classes.background}>
      <AddCategory
        setIsOpen={setIsOpen}
        categories={categories}
        onDeleteTag={onDeleteTag}
        className={className}
      />
      <SelectCategoryModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onCategoryClicked={e => {
          const newCategory = {
            id: e.id,
            value: e.t.name
          };
          onCategoryClicked([...categories, newCategory]);
        }}
        isCategoryBold={isCategoryBold}
      />
    </div>
  );
};

CreateCategory.propTypes = {
  categories: array.isRequired,
  onCategoryClicked: func.isRequired,
  onDeleteTag: func.isRequired
};

export const AddCategory = ({
  setIsOpen,
  categories,
  onDeleteTag,
  className
}) => {
  const classes = useStyles();
  const language = "en";
  const { t } = useTranslation(language);
  return (
    <Box marginBottom={1} className={classes.radioGroup} flexDirection="column">
      <Box
        onClick={() => setIsOpen(true)}
        marginBottom={1}
        component={ButtonBase}
        className={clsx(className, classes.buttonAddCategory)}
      >
        <AddIcon style={{ marginLeft: -8 }} />
        <Typography>{t("add_category")}</Typography>
      </Box>

      <Grid container>
        {categories.map((category, index) => (
          <CategoryTag
            categories={category}
            key={category.id}
            onDelete={onDeleteTag}
            index={index}
          />
        ))}
      </Grid>
    </Box>
  );
};

AddCategory.propTypes = {
  setIsOpen: func.isRequired,
  categories: array.isRequired,
  onDeleteTag: func.isRequired
};

export default CreateCategory;
